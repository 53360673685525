import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../images/logo.png';
import './styles.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelClass: { display: 'block' },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.moveLabel = this.moveLabel.bind(this);
    this.displayLabel = this.displayLabel.bind(this);
    this.selectText = this.selectText.bind(this);
  }

  handleSubmit(event) {
    this.context.mixpanel.track('Signup');
    event.target.form.submit();
  }

  moveLabel(event) {
    if (event.target.value.length > 0) {
      this.setState({ labelClass: { display: 'none' } });
    } else {
      this.setState({ labelClass: { display: 'block' } });
    }
  }

  selectText(event) {
    event.target.select();
  }

  displayLabel(event) {
    if (event.target.value.length === 0) {
      this.setState({ labelClass: { display: 'block' } });
    }
  }

  componentDidMount() {
    this.context.mixpanel.track('Home.Load');
  }

  render() {
    return (
      <div className="c-header-container">
        <form
          action="https://nycode.us8.list-manage.com/subscribe/post?u=ecb050263d8d76b7d12caca00&amp;id=89b7f18fa7"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <div className="c-header">
            <h1>Glass of Beer</h1>
            <img src={logo} alt="illustration of a glass of beer" />
            <div className="c-header__bottom">
              <h2>Five of the best links for beer and brewing in your inbox every week.</h2>
              <div className="c-header__input-container">
                <input
                  id="email-input"
                  type="email"
                  name="EMAIL"
                  className="c-header__input-field"
                  onFocus={this.selectText}
                  onChange={this.moveLabel}
                  onBlur={this.displayLabel}
                />
                <label htmlFor="email-input" id="email-label" style={this.state.labelClass}>
                  Your email here
                </label>
              </div>
              <button className="c-header__button" onClick={this.handleSubmit}>
                Subscribe
              </button>
              <div
                style={{
                  position: 'absolute',
                  left: '-5000px',
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_ecb050263d8d76b7d12caca00_89b7f18fa7"
                  tabIndex="-1"
                  value=""
                  onChange={function(event) {
                    return true;
                  }}
                />
              </div>
            </div>
            <footer>
              © 2018-{new Date().getFullYear()}{' '}
              <a href="https://christopherandersson.com">Christopher Andersson</a>
            </footer>
          </div>
        </form>
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

Header.contextTypes = {
  mixpanel: PropTypes.object.isRequired,
};

export default Header;
