import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from '../header';
import './styles.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet title={data.site.siteMetadata.title}>
          <html lang="en" />
          <meta name="description" content={data.site.siteMetadata.description} />
          <meta
            name="keywords"
            content="homebrew,beer,craft beer,home brew,brewing,brewing beer,christopher andersson"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Mukta+Malar:200,300,400,500,600,700,800|Bungee"
            rel="stylesheet"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="57x57"
            href="/apple-touch-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="/apple-touch-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="/apple-touch-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="/apple-touch-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="60x60"
            href="/apple-touch-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="120x120"
            href="/apple-touch-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="76x76"
            href="/apple-touch-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="152x152"
            href="/apple-touch-icon-152x152.png"
          />
          <link rel="icon" type="image/png" href="/favicon-196x196.png" sizes="196x196" />
          <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
          <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
          <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
          <link rel="icon" type="image/png" href="/favicon-128.png" sizes="128x128" />
          <meta name="application-name" content="Glass of Beer" />
          <meta name="msapplication-TileColor" content="#FFFFFF" />
          <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
          <meta name="msapplication-square70x70logo" content="/mstile-70x70.png" />
          <meta name="msapplication-square150x150logo" content="/mstile-150x150.png" />
          <meta name="msapplication-wide310x150logo" content="/mstile-310x150.png" />
          <meta name="msapplication-square310x310logo" content="/mstile-310x310.png" />
        </Helmet>
        <div className="c-split">
          <Header />
          <div className="c-articles">{children}</div>
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
